import React, {FC} from 'react';
import './styles.scss';

const RequestError: FC = () => {
  return (
    <>
      <p>if request is not successful you will get</p>
      <div className="json-title-block">
        <span>JSON representation</span>
      </div>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": true,</p>
        <p className="mgl-25">"message": String</p>
        <p>{'}'}</p>
      </pre>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">error</td>
            <td>
              <p className="bold-text">boolean</p>
              <p className="normal-text">if the error returns true, it means that an error has occurred</p>
            </td>
          </tr>
          <tr>
            <td className="td-fill">message</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">the message contains information about the error</p>
            </td>
          </tr>
        </tbody>
      </table>
      <h5>Example</h5>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": true,</p>
        <p className="mgl-25">"message": "invalid API key"</p>
        <p>{'}'}</p>
      </pre>
      <br />
      <br />
      <br />
    </>
  );
};

export default RequestError;
